import { CompanyLogo } from "../../../styles/component/style";
import { Images } from "../../../assets/images";
import {
  LogoContainer,
  LinksContainer,
  ProfileImage,
  ProfileName,
  LogoutText,
  BigScreen,
  SmallScreen,
  LogoutIcon,
  Row,
  AgentTag,
  HelpIcon,
  // AgentPortalText,
} from "./Styles";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import COLORS from "../../../assets/Colors";
import useUser from "../../../hooks/useUser";
import { navigate } from "@reach/router";
import labels from "../../../helpers/labels.json";
import useAlert from "../../../hooks/useAlert";
import { PrimaryOutlinedCTAButton } from "../../common/Buttons";
import Notification from "../Notification";
import useSettings from "../../../hooks/useSettings";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.PRIMARY_WHITE,
    height: 74,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "none",
    borderBottom: "1px solid #e1e3ee",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

export default function StyledAppBar() {
  const classes = useStyles();
  const { userObject, token, setToken } = useUser();
  const { showAlert, hideAlert } = useAlert();
  const { setting, settingsLoading } = useSettings();

  const handleLogout = () =>
    showAlert({
      heading: "Logout?",
      desc: "Are you sure you want to logout?",
      primaryBtnText: "Logout",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        setToken("logged_out");
        hideAlert();
      },
      isOpen: true,
      onDialogClose: () => hideAlert(),
    });

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <LogoContainer>
          {settingsLoading ? null : (
            <CompanyLogo
              onClick={() => navigate("/arbitrator/cases")}
              src={setting?.logoUrl || Images.companyLogo}
            />
          )}
        </LogoContainer>
        <BigScreen>
          <LinksContainer>
            {!token ? (
              <>
                <PrimaryOutlinedCTAButton
                  style={{
                    marginRight: 38,
                    height: 39,
                    width: 124,
                    padding: "11px 38px",
                  }}
                  onClick={() => navigate("/signup")}
                >
                  {labels["appbar.signup"]}
                </PrimaryOutlinedCTAButton>
                <PrimaryOutlinedCTAButton
                  style={{
                    marginRight: 38,
                    height: 39,
                    width: 124,
                    padding: "11px 38px",
                  }}
                  onClick={() => navigate("/")}
                >
                  {labels["appbar.login"]}
                </PrimaryOutlinedCTAButton>
              </>
            ) : (
              <>
                <div>
                  <Notification />
                </div>
                <Row onClick={() => navigate("/arbitrator/help")}>
                  <HelpIcon
                    src={require("../../../assets/images/HelpHeader.svg")}
                  />
                  <ProfileName>{labels["appbar.help"]}</ProfileName>
                </Row>
                <Row onClick={handleLogout}>
                  <LogoutIcon
                    src={require("../../../assets/images/logout.svg")}
                  />
                  <LogoutText>{labels["appbar.logout"]}</LogoutText>
                </Row>
                <ProfileImage
                  src={
                    userObject.avatarUrl
                      ? userObject.avatarUrl
                      : require("../../../assets/images/userPlaceholder.svg")
                  }
                />

                <ProfileName onClick={() => navigate("/arbitrator/profile")}>
                  <div>{userObject.name}</div>
                  <AgentTag>Neutral</AgentTag>
                </ProfileName>
              </>
            )}
          </LinksContainer>
        </BigScreen>
        <SmallScreen>
          {!token ? null : (
            <>
              <Row onClick={() => navigate("/arbitrator/help")}>
                <HelpIcon
                  src={require("../../../assets/images/HelpHeader.svg")}
                />
                <ProfileName>{labels["appbar.help"]}</ProfileName>
              </Row>
              <Row onClick={handleLogout}>
                <LogoutIcon
                  src={require("../../../assets/images/logout.svg")}
                />
                <LogoutText>{labels.logout}</LogoutText>
              </Row>
              <ProfileImage
                src={
                  userObject.avatarUrl
                    ? userObject.avatarUrl
                    : require("../../../assets/images/userPlaceholder.svg")
                }
              />

              <ProfileName>{userObject.name}</ProfileName>
            </>
          )}
        </SmallScreen>
      </Toolbar>
    </AppBar>
  );
}
